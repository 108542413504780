export const firebaseConfig = {
  apiKey: "AIzaSyBksyh9AgjL5IIcwtC399-pNlXKeka06ps",
  authDomain: "gogo-remote.firebaseapp.com",
  databaseURL: "https://gogo-remote-default-rtdb.firebaseio.com",
  projectId: "gogo-remote",
  storageBucket: "gogo-remote.appspot.com",
  messagingSenderId: "293864600225",
  appId: "1:293864600225:web:594e843a3e6539060af6e0",
  measurementId: "G-ZJF4VX11Y9",
};
